<template>
  <div class="view view--policies view--policies-tnc">
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-3">{{ cc.tit }}</h1>
            <h2 class="tit fw-500 fc-dark ts-26 ts-md-32 mb-4">{{ cc.txt }}</h2>
          </div>
          <div class="col-12 col-lg-6" v-for="(item, i) in headings" :key="`policy-cont-${i}`">
            <p class="txt fw-400 ts-19 txt--policy-anchor mb-2"
               v-for="(tit, t) in item" :key="`policy-cont-item-${t}`"
               @click.prevent="goToAnchor(i, t)" v-html="`${i === 0 ? t + 1 : t + 16}. ${tit}`">
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section py-4" v-for="(item, i) in cc.content" :key="`policy-sec-${i}`" :id="`policy-anchor-${i}`">
      <div class="container">
        <div class="row mb-3">
          <div class="col-12">
            <h2 class="tit fw-500 fc-dark ts-26 ts-md-32" v-html="`${i + 1}. ${cc.headings[i]}`" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <comp-policy :data="item" :pid="i + 1"/>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CompPolicy from '@/components/Policy'
export default {
  components: {
    CompPolicy
  },
  computed: {
    cc () {
      return this.$t('views.policies.tnc')
    },
    headings () {
      const arr = this.cc.headings
      const s = Math.ceil(arr.length / 2)
      return [arr.slice(0, s), arr.slice(s)]
    }
  },
  methods: {
    goToAnchor (i, t) {
      const id = i === 0 ? t : t + 15
      const target = document.getElementById(`policy-anchor-${id}`)
      window.scrollTo({ top: target.offsetTop, behavior: 'smooth' })
    }
  },
  metaInfo () {
    const meta = this.$t('meta.policies.tnc')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
